const CHROME_WHEEL_PIXELS = 53
const FIREFOX_WHEEL_LINES = 3

export const getWheelDelta = (evt: WheelEvent) => {
  let delta = -evt.deltaY

  switch (evt.deltaMode) {
    case WheelEvent.DOM_DELTA_PIXEL:
      delta /= CHROME_WHEEL_PIXELS
      break

    case WheelEvent.DOM_DELTA_LINE:
      delta /= FIREFOX_WHEEL_LINES
      break

    case WheelEvent.DOM_DELTA_PAGE:
      delta = Math.sign(delta)
      break
  }

  return delta
}
