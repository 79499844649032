import type { MaybeRefOrGetter } from 'vue'

export const CLICK_COUNT_INFO_DELIMITER = '/'

export const useClickCountInfo = (
  availableClickCountAny: MaybeRefOrGetter<number>,
  maxClickCountAny: MaybeRefOrGetter<number>
) => {
  const availableClickCount = toRef(availableClickCountAny)
  const maxClickCount = toRef(maxClickCountAny)

  const clickCountInfo = computed(() => {
    const primary = String(availableClickCount.value)
    const postfix = Number.isFinite(maxClickCount.value)
      ? CLICK_COUNT_INFO_DELIMITER + maxClickCount.value
      : ''

    return primary + postfix
  })

  return { clickCountInfo }
}
