<template>
  <div :class="$style.root" @wheel.prevent>
    <div
      ref="canvasContainer"
      :class="[
        $style.canvasContainer,
        {
          [$style.grab]: !activeColor,
          [$style.grabbing]: !activeColor && pressed
        }
      ]"
      @wheel.passive="onWheel"
    >
      <div :class="$style.scaler" :style="{ transform: scalerTransform }">
        <div
          :class="$style.translator"
          :style="{ transform: translatorTransform }"
        >
          <div
            :style="{
              width: game.width + 'px',
              height: game.height + 'px'
            }"
          >
            <canvas-element />
          </div>

          <game-pencil />
          <game-grid />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useMousePressed, useElementBounding } from '@vueuse/core'
import interact from 'interactjs'
import CanvasElement from './CanvasElement.vue'
import GamePencil from '../GamePencil.vue'
import GameGrid from '../GameGrid.vue'
import { useGameStore } from '../../stores/game'
import { useScale } from '../../composables/use-scale'
import { useTranslate } from '../../composables/use-translate'
import { getWheelDelta } from '../../utils/wheel'
import { MIN_SCALE_PINCH_SENSITIVITY } from '../../configs'

const gameStore = useGameStore()
const game = computed(() => gameStore.data!)
const { activeColor } = storeToRefs(gameStore)

const canvasContainer = ref<HTMLElement | null>(null)

const { width, height, top, left } = useElementBounding(canvasContainer)
const { pressed } = useMousePressed({ target: canvasContainer, touch: false })

const { scale, setScale, nudgeScale } = useScale()
const scalerTransform = computed(
  () => `translate3d(0, 0, 0) scale(${scale.value})`
)

const { translateX, nudgeX, translateY, nudgeY } = useTranslate(
  () => game.value.width,
  () => game.value.height
)
const translatorTransform = computed(
  () => `translate3d(${translateX.value}px, ${translateY.value}px, 0)`
)

const onWheel = (evt: WheelEvent) => {
  const delta = getWheelDelta(evt)

  const oldScale = scale.value
  nudgeScale(delta)

  if (scale.value === oldScale) {
    return
  }

  const dx = evt.clientX - left.value - width.value / 2
  const dy = evt.clientY - top.value - height.value / 2

  nudgeX(dx, oldScale)
  nudgeX(-dx)

  nudgeY(dy, oldScale)
  nudgeY(-dy)
}

const startInteraction = () => {
  if (!canvasContainer.value) {
    throw new Error('Canvas container does not exist')
  }

  const onmove = (evt: any) => {
    nudgeX(-evt.dx)
    nudgeY(-evt.dy)
  }

  interact(canvasContainer.value)
    .styleCursor(false)
    .draggable({
      inertia: true,
      onmove
    })
    .gesturable({
      onmove: evt => {
        setScale(
          scale.value +
            Math.max(scale.value, MIN_SCALE_PINCH_SENSITIVITY) * evt.ds
        )

        onmove(evt)
      }
    })
}

onMounted(() => {
  startInteraction()
})
</script>

<style lang="scss" module>
.root,
.canvasContainer {
  height: 100%;
}

.canvasContainer {
  --scale-correction-factor: 20; // to render more accurately
  position: relative;
  overflow: hidden;
  touch-action: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scaler {
  position: absolute;
}

.translator {
  position: relative;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}
</style>
