import { useStorage, whenever } from '@vueuse/core'
import { useAccountStore } from '~/stores/account'
import { useClansMeStore } from '~/stores/clans/me'
import { useGameStore } from '../stores/game'
import { IS_GAME_OVER } from '../configs'
import { getGuideConfig } from '../guides'

export const useOnboarding = (initOnMounted = true) => {
  const { $dialog, $guide } = useNuxtApp()

  const accountStore = useAccountStore()
  const { isAuthorized } = storeToRefs(accountStore)
  const clansMeStore = useClansMeStore()
  const { data: clan } = storeToRefs(clansMeStore)
  const { fetchData: fetchClan } = clansMeStore
  const gameStore = useGameStore()
  const { data: game, maxClickCount } = storeToRefs(gameStore)

  const onboarding = useStorage(
    'pixelbattle-onboarding',
    {
      closed: {
        intro: false,
        clan: false,
        gameOver: false
      },
      stopped: {
        guide: false
      }
    },
    undefined,
    {
      initOnMounted,
      mergeDefaults: true
    }
  )

  const startGuide = async () => {
    if (!game.value) {
      return
    }

    const guideConfig = getGuideConfig({
      maxClickCount: maxClickCount.value,
      restoreTime: game.value.default_restore_time
    })

    const state = await $guide.create(guideConfig)

    onboarding.value.stopped.guide = true

    return state
  }

  const openClanDialog = () => {
    const config: Parameters<typeof $dialog>[0] = {
      blur: false,
      onClose: () => {
        onboarding.value.closed.clan = true
      },
      onClosed: startGuide
    }

    if (!clan.value) {
      config.content = defineAsyncComponent(
        () => import('../components/dialogs/onboarding/clan/SoloPlayer.vue')
      )
    } else if (clan.value.clanMember.role.id === 'owner') {
      config.content = defineAsyncComponent(
        () => import('../components/dialogs/onboarding/clan/ClanLeader.vue')
      )
    } else {
      config.content = defineAsyncComponent(
        () => import('../components/dialogs/onboarding/clan/ClanMember.vue')
      )
    }

    return $dialog(config)
  }

  const openIntroDialog = () =>
    $dialog({
      blur: false,
      content: defineAsyncComponent(
        () => import('../components/dialogs/onboarding/IntroContent.vue')
      ),
      onClose: () => {
        onboarding.value.closed.intro = true
      },
      onClosed: () => {
        if (isAuthorized.value) {
          openClanDialog()
        }
      }
    })

  const openGameOverDialog = () =>
    $dialog({
      blur: false,
      content: defineAsyncComponent(
        () => import('../components/dialogs/onboarding/GameOverContent.vue')
      ),
      onClose: () => {
        onboarding.value.closed.gameOver = true
      }
    })

  const hasAlreadyStarted = useState(() => false)

  const initOnboarding = () => {
    if (hasAlreadyStarted.value) {
      return
    }

    hasAlreadyStarted.value = true

    if (IS_GAME_OVER) {
      if (!onboarding.value.closed.gameOver) {
        openGameOverDialog()
      }

      return
    }

    whenever(isAuthorized, async () => {
      await fetchClan()

      if (!onboarding.value.closed.clan) {
        openClanDialog()
      }
    })

    if (!onboarding.value.closed.intro) {
      openIntroDialog()

      return
    }

    if (!onboarding.value.closed.clan && isAuthorized.value) {
      openClanDialog()

      return
    }

    if (!onboarding.value.stopped.guide && isAuthorized.value) {
      startGuide()
    }
  }

  return {
    onboarding,
    hasAlreadyStarted,
    startGuide,
    openClanDialog,
    openIntroDialog,
    initOnboarding
  }
}
