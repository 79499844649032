import type { MaybeRefOrGetter } from 'vue'
import { inBetween } from '../utils/number'
import type { LockZone } from '../types'

export const usePointerCoords = (
  lockZonesAny: MaybeRefOrGetter<LockZone[]>
) => {
  const lockZones = toRef(lockZonesAny)

  const x = useState(() => 0)
  const y = useState(() => 0)
  const isCanvasHovered = useState(() => false)

  const isInLockZone = computed(() =>
    lockZones.value.some(
      ({ x1, y1, x2, y2 }) =>
        inBetween(x.value, x1, x2) && inBetween(y.value, y1, y2)
    )
  )

  return { x, y, isCanvasHovered, isInLockZone }
}
