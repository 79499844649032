import { useOnboarding } from '../composables/use-onboarding'

export const PENCIL_ID = 'pxlPencil'
export const ZOOM_ID = 'pxlZoom'
export const SETTINGS_ID = 'pxlSettings'
export const GUIDE_ID = 'pxlGuide'

const stepConfigDefaults = {
  className: 'pxlGuide',
  theme: 'classic',

  overlay: {
    opacity: 0,
    pointerEvents: 'all'
  },

  scroll: {
    lock: true
  }
}

const importantButtonConfigDefaults = {
  text: 'Понял!',
  theme: 'important'
}

const secondaryButtonConfigDefaults = {
  text: 'Закончить',
  theme: 'secondary',
  emit: 'skip'
}

export const getGuideConfig = ({
  maxClickCount,
  restoreTime
}: {
  maxClickCount: number
  restoreTime: number
}) => [
  {
    ...stepConfigDefaults,

    element: '#' + PENCIL_ID,
    position: 'top',
    title: 'Панель цветов и\xA0карандаш',
    text: `Кликни на\xA0нужный цвет в\xA0панели, чтобы выбрать его для нанесения на\xA0полотно. С\xA0помощью карандаша ты\xA0можешь закрашивать пиксели. У\xA0карандаша есть ${maxClickCount}\xA0зарядов, один заряд восстанавливается ${restoreTime}\xA0секунд.`,

    offset: {
      top: -7
    },

    buttons: [importantButtonConfigDefaults, secondaryButtonConfigDefaults]
  },

  {
    ...stepConfigDefaults,

    element: '#' + ZOOM_ID,
    title: 'Зум',
    text: 'Используй зум, чтобы приближать или отдалять полотно пикселей.\n\nВсе для твоего удобства!',

    offset: {
      top: 16
    },

    buttons: [importantButtonConfigDefaults, secondaryButtonConfigDefaults]
  },

  {
    ...stepConfigDefaults,

    element: '#' + SETTINGS_ID,
    position: 'right',
    title: 'Настройки',
    text: 'Здесь ты\xA0можешь настроить звук и\xA0другие фишки Pixel Battle.',

    offset: {
      left: -4
    },

    buttons: [importantButtonConfigDefaults, secondaryButtonConfigDefaults]
  },

  {
    ...stepConfigDefaults,

    title: 'История покраски',
    text: 'Наведи на\xA0любой пиксель, чтобы узнать, кто и\xA0когда покрасил его в\xA0последний раз.',
    buttons: [importantButtonConfigDefaults, secondaryButtonConfigDefaults]
  },

  {
    ...stepConfigDefaults,

    element: '#' + GUIDE_ID,
    position: 'right',
    title: 'Еще разок',
    text: 'Удачи и\xA0творческого вдохновения! Если что-то забудешь, ты\xA0можешь пройти обучение еще раз.',

    offset: {
      left: -4
    },

    buttons: [
      {
        ...importantButtonConfigDefaults,

        text: 'Все понял!'
      },
      {
        ...secondaryButtonConfigDefaults,

        text: 'Показать еще раз',

        cb: () => {
          const { openIntroDialog } = useOnboarding()
          openIntroDialog()
        }
      }
    ]
  }
]
