<template>
  <div :class="$style.colorPicker">
    <transition :name="$style.fade" mode="out-in">
      <div
        v-if="availableClickCount"
        :id="PENCIL_ID"
        :class="[
          $style.pencil,
          {
            [$style.active]: activeColor
          }
        ]"
        :style="{
          color: activeColor?.value ?? 'transparent'
        }"
      >
        <div
          :class="$style.pencilContent"
          :style="{
            color: oppositeActiveColor ?? 'white'
          }"
        >
          <ui-icon
            name="pencil"
            :style="{
              color: oppositeActiveColor ?? 'var(--app-color-gray)'
            }"
          />

          <span :style="{ minWidth: clickCountInfoMinWidth }">{{
            clickCountInfo
          }}</span>
        </div>
      </div>
      <div v-else-if="timerRemaining" :class="$style.timer">
        <svg
          :class="$style.timerBorder"
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="`0 0 ${TIMER_BORDER_VIEW_BOX_WIDTH} ${TIMER_BORDER_VIEW_BOX_HEIGHT}`"
          aria-hidden="true"
        >
          <rect
            :width="TIMER_BORDER_VIEW_BOX_WIDTH"
            :height="TIMER_BORDER_VIEW_BOX_HEIGHT"
            fill="none"
            stroke="#FFFFFF33"
            :stroke-width="TIMER_BORDER_STROKE_WIDTH"
          />

          <rect
            :width="TIMER_BORDER_VIEW_BOX_WIDTH"
            :height="TIMER_BORDER_VIEW_BOX_HEIGHT"
            fill="none"
            :stroke="activeColor?.value ?? 'transparent'"
            :stroke-width="TIMER_BORDER_STROKE_WIDTH"
            :stroke-dasharray="timerBorderStrokeDasharray"
            :stroke-dashoffset="timerBorderStrokeDashoffset"
          />
        </svg>

        <div :class="$style.timerContent">
          <ui-icon v-if="isTimerIconShown" name="timer" />

          <span :style="{ minWidth: timerRemainingMinWidth }">{{
            timerRemaining
          }}</span>
        </div>
      </div>
    </transition>

    <div
      :class="[
        $style.colorListWrapper,
        { [$style.shadowed]: !arrivedState.right }
      ]"
    >
      <div ref="colorList" v-drag-scroll-x :class="$style.colorList">
        <button
          v-for="color in extendedColors"
          :key="color.id"
          v-static-click
          :class="[
            $style.color,
            {
              [$style.active]: color.isActive
            }
          ]"
          :style="{
            color: color.value
          }"
          type="button"
          :aria-label="color.ariaLabel"
          @static-click="activeColor = color.isActive ? null : color"
        >
          <ui-icon
            v-if="color.isActive"
            name="close"
            :style="{
              color: oppositeActiveColor
            }"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<i18n>
  {
    "ru": {
      "colorAriaLabelPrefix": "Выбрать цвет ",
      "pickedColorAriaLabelPrefix": "Текущий цвет, "
    }
  }
</i18n>

<script lang="ts" setup>
import { useScroll } from '@vueuse/core'
import UiIcon from '~/components/ui/icon/index.vue'
import { vDragScrollX } from '~/directives/drag-scroll-x'
import { vStaticClick } from '~/directives/static-click'
import {
  CLICK_COUNT_INFO_DELIMITER,
  useClickCountInfo
} from '../../composables/use-click-count-info'
import { PENCIL_ID } from '../../guides'
import type { Color } from '../../types'

const MAX_LUMA_OF_PENCIL_LIGHT_COLOR = 153

const CLICK_COUNT_INFO_SYMBOL_WIDTH = 0.6875
const CLICK_COUNT_INFO_DELIMITER_WIDTH = 0.375

const TIMER_BORDER_VIEW_BOX_WIDTH = 60
const TIMER_BORDER_VIEW_BOX_HEIGHT = 40
const TIMER_BORDER_STROKE_WIDTH = 2
const TIMER_MAX_LENGTH_TO_SHOW_ICON = 3
const TIMER_REMAINING_SYMBOL_WIDTH = 0.7

const props = withDefaults(
  defineProps<{
    colors: Color[]
    availableClickCount?: number
    maxClickCount?: number
    timerTotal?: number
    timerRemaining?: number
  }>(),
  {
    availableClickCount: 0,
    maxClickCount: Infinity,
    timerTotal: 0,
    timerRemaining: 0
  }
)

const activeColor = defineModel<Color | null>()

const colorList = ref<HTMLElement | null>(null)
const { arrivedState } = useScroll(colorList)

const { t } = useI18n()

const oppositeActiveColor = computed(() => {
  if (!activeColor.value) {
    return
  }

  const luma = RGBComponentsToLuma(...activeColor.value.rgba)

  return luma > MAX_LUMA_OF_PENCIL_LIGHT_COLOR
    ? 'var(--app-color-black)'
    : 'white'
})

const { clickCountInfo } = useClickCountInfo(
  () => props.availableClickCount,
  () => props.maxClickCount
)

const extendedColors = computed(() =>
  props.colors.map(color => {
    const isActive = color.id === activeColor.value?.id

    return {
      ...color,

      isActive,

      ariaLabel:
        t(isActive ? 'pickedColorAriaLabelPrefix' : 'colorAriaLabelPrefix') +
        color.value
    }
  })
)

const clickCountInfoMinWidth = computed(() => {
  const hasDelimiter = clickCountInfo.value.includes(CLICK_COUNT_INFO_DELIMITER)

  const symbolsWidth =
    (clickCountInfo.value.length - Number(hasDelimiter)) *
    CLICK_COUNT_INFO_SYMBOL_WIDTH
  const delimiterWidth = hasDelimiter ? CLICK_COUNT_INFO_DELIMITER_WIDTH : 0

  return symbolsWidth + delimiterWidth + 'em'
})

const timerBorderStrokeDasharray = computed(
  () => 2 * (TIMER_BORDER_VIEW_BOX_WIDTH + TIMER_BORDER_VIEW_BOX_HEIGHT)
)

const timerBorderStrokeDashoffset = computed(
  () =>
    (props.timerRemaining / props.timerTotal) * timerBorderStrokeDasharray.value
)

const isTimerIconShown = computed(
  () => String(props.timerRemaining).length <= TIMER_MAX_LENGTH_TO_SHOW_ICON
)

const timerRemainingMinWidth = computed(
  () =>
    String(props.timerRemaining).length * TIMER_REMAINING_SYMBOL_WIDTH + 'em'
)
</script>

<style lang="scss" module>
.colorPicker {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.pencil {
  border-radius: 0.2em;
  border: 0.1em solid transparent;
  padding: 0 0.3em 0 0.2em;
  min-width: 6em;
  flex-shrink: 0;
  height: 4em;
  background-color: currentColor;
  transition-property: border-color, color;
  transition-duration: 0.2s;

  &.active {
    border-color: rgba(white, 0.2);
  }
}

.pencilContent {
  height: 100%;
  transition: color 0.2s;
  display: flex;
  gap: 0.1em;
  justify-content: center;
  align-items: center;

  svg {
    width: 2.4em;
    height: 2.4em;
    transition: color 0.2s;
  }

  span {
    text-align: center;
    font-family: var(--primary-font);
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.5;
  }
}

.timer {
  width: 6em;
  flex-shrink: 0;
  height: 4em;
  position: relative;
}

.timerContent {
  height: 100%;
  position: relative;
  display: flex;
  gap: 0.2em;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.2em;
    height: 1.2em;
  }

  span {
    text-align: center;
    font-family: var(--primary-font);
    font-size: 1.6em;
    font-weight: 600;
    line-height: 1.5;
  }
}

.timerBorder {
  border-radius: 0.2em;
  position: absolute;
  inset: 0;

  rect {
    &:last-child {
      transition:
        stroke 0.3s linear,
        stroke-dashoffset 1s linear;
    }
  }
}

.colorListWrapper {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    width: 2em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
    background-image: linear-gradient(
      to right,
      rgba(var(--overflow-shadow-rgb), 0),
      rgb(var(--overflow-shadow-rgb))
    );
  }

  &.shadowed {
    &::after {
      opacity: 1;
    }
  }
}

.colorList {
  --padding-x: 0.5em;

  @include hide-scrollbar;

  padding: 2em var(--padding-x);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 var(--padding-x);
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.8em;
}

.color {
  border-radius: 0.2em;
  border: 0.1em solid rgba(white, 0.2);
  width: 4em;
  flex-shrink: 0;
  height: 4em;
  background-color: currentColor;
  scroll-snap-align: start;
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  will-change: transform;

  svg {
    width: 1.8em;
    height: 1.8em;
  }

  &.active {
    box-shadow: 0 0.4em 1em;
    transform: scale(1.15) translateY(-0.3em);
  }

  &:hover,
  &:focus-visible {
    box-shadow: 0 0.7em 1.8em;
  }
}

.fade {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition: opacity 0.3s;
  }

  &:global(-leave-to),
  &:global(-enter-from) {
    opacity: 0;
  }
}
</style>
