<template>
  <div
    v-show="isVisible"
    ref="grid"
    :class="$style.grid"
    :style="{ background }"
  />
</template>

<script lang="ts" setup>
import { useScale } from '../composables/use-scale'
import { useSettings } from '../composables/use-settings'

const MIN_VISIBILITY_SCALE = 8

const grid = ref<HTMLElement | null>(null)

const { scale } = useScale()
const { settings } = useSettings()

const background = computed(() => {
  if (!grid.value) {
    return
  }

  const scaleCorrectionFactor =
    Number(
      getComputedStyle(grid.value).getPropertyValue('--scale-correction-factor')
    ) || 1

  const borderSize = scaleCorrectionFactor / scale.value

  const colorStops = `var(--border-color) ${borderSize}px, transparent ${borderSize}px`

  const horizontalBorders = `linear-gradient(to bottom, ${colorStops}) left top / ${scaleCorrectionFactor}px ${scaleCorrectionFactor}px`
  const horizontalClosingBorder = `linear-gradient(to top, ${colorStops}) no-repeat left bottom / 100% ${scaleCorrectionFactor}px`
  const verticalBorders = `linear-gradient(to right, ${colorStops}) left top / ${scaleCorrectionFactor}px ${scaleCorrectionFactor}px`
  const verticalClosingBorder = `linear-gradient(to left, ${colorStops}) no-repeat right top / ${scaleCorrectionFactor}px 100%`

  return `${horizontalBorders}, ${horizontalClosingBorder}, ${verticalBorders}, ${verticalClosingBorder}`
})

const isVisible = computed(
  () => settings.value.boolean.grid && scale.value >= MIN_VISIBILITY_SCALE
)
</script>

<style lang="scss" module>
.grid {
  --border-color: #bbb;

  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% * var(--scale-correction-factor));
  height: calc(100% * var(--scale-correction-factor));
  transform: scale(calc(1 / var(--scale-correction-factor)));
  transform-origin: left top;
  pointer-events: none;
}
</style>
