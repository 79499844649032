import type { Directive } from 'vue'
import { createClickChecker } from '~/utils/pointer'

type Handler = (evt: PointerEvent) => void

export type StaticClickEvent = CustomEvent<PointerEvent>

export const vStaticClick = (() => {
  let onDown: Handler
  let onUp: Handler

  return {
    mounted(el, { value }) {
      const clickChecker = createClickChecker(value)

      onDown = evt => clickChecker.handlePointerdown(evt)

      onUp = evt => {
        const isStaticClick = clickChecker.handlePointerup(evt)

        if (isStaticClick) {
          el.dispatchEvent(new CustomEvent('static-click', { detail: evt }))
        }
      }

      el.addEventListener('pointerdown', onDown)
      el.addEventListener('pointerup', onUp)
    },

    unmounted(el) {
      el.removeEventListener('pointerdown', onDown)
      el.removeEventListener('pointerup', onUp)
    }
  } satisfies Directive<HTMLElement, number | undefined>
})()
