import type { BaseColor, Color } from '../types'

export const extendBaseColor = (baseColor: BaseColor): Color => {
  const {
    rgba: [r, g, b, a]
  } = baseColor

  return {
    ...baseColor,

    rgba8: [r, g, b, Math.round(a * 255)],
    value: toRGBString(r, g, b, a)
  }
}

export const getMappedColors = (colors: Color[]) =>
  colors.reduce((acc, color) => {
    acc.set(color.id, color)

    return acc
  }, new Map<Color['id'], Color>())
