<template>
  <div
    v-show="isShown"
    :class="$style.pencil"
    :style="{
      backgroundColor: activeColor?.value,
      transform
    }"
  />
</template>

<script lang="ts" setup>
import { useGameStore } from '../stores/game'
import { usePointerCoords } from '../composables/use-pointer-coords'

const gameStore = useGameStore()
const game = computed(() => gameStore.data!)
const { activeColor } = storeToRefs(gameStore)

const { x, y, isCanvasHovered, isInLockZone } = usePointerCoords(
  () => game.value.lockZones
)

const isShown = computed(
  () => activeColor.value && isCanvasHovered.value && !isInLockZone.value
)
const transform = computed(
  () => `translate3d(${x.value}px, ${y.value}px, 0)
    scale(calc(1 / var(--scale-correction-factor)))`
)
</script>

<style lang="scss" module>
.pencil {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(1px * var(--scale-correction-factor));
  height: calc(1px * var(--scale-correction-factor));
  transform-origin: left top;
  pointer-events: none;
}
</style>
