export const isTouchPen = (evt: PointerEvent) =>
  ['touch', 'pen'].includes(evt.pointerType)

export const isMouseDown = (evt: PointerEvent) =>
  evt.pointerType === 'mouse' && evt.type === 'pointerdown'

export const isTouchPenMove = (evt: PointerEvent) =>
  isTouchPen(evt) && evt.type === 'pointermove'

export const isPrimaryButton = (evt: PointerEvent) =>
  !(evt.pointerType === 'mouse' && evt.button)

export const createClickChecker = (epsilon = 5) => {
  let downClientX = -1
  let downClientY = -1
  let button = -1

  const handlePointerdown = (evt: PointerEvent) => {
    downClientX = evt.clientX
    downClientY = evt.clientY
    button = evt.button
  }

  const handlePointerup = (evt: PointerEvent) => {
    const dx = Math.abs(evt.clientX - downClientX)
    const dy = Math.abs(evt.clientY - downClientY)

    const isSameButton = evt.button === button
    const isAcceptableDiff = dx < epsilon && dy < epsilon

    return isSameButton && isPrimaryButton(evt) && isAcceptableDiff
  }

  return {
    handlePointerdown,
    handlePointerup
  }
}
