import { useStorage } from '@vueuse/core'
import partial from 'lodash/partial'

export const useSettings = (initOnMounted = true) => {
  const settings = useStorage(
    'pixelbattle-settings',
    {
      boolean: {
        sound: true,
        grid: true
      }
    },
    undefined,
    {
      initOnMounted,
      mergeDefaults: true
    }
  )

  const toggleBoolean = (
    key: keyof typeof settings.value.boolean,
    value?: boolean
  ) => {
    settings.value.boolean[key] = value ?? !settings.value.boolean[key]
  }

  return {
    settings: readonly(settings),
    toggleSound: partial(toggleBoolean, 'sound'),
    toggleGrid: partial(toggleBoolean, 'grid')
  }
}
