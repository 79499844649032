<template>
  <canvas
    ref="canvas"
    v-ws-action:pixelbattle="{
      connection: onConnectionAction,
      updatePixel: onUpdatePixelAction
    }"
    v-static-click
    :class="[
      $style.canvas,
      {
        [$style.pixelated]: isPixelated,
        [$style.notAllowed]: activeColor && isInLockZone
      }
    ]"
    :width="game.width"
    :height="game.height"
    @pointermove="handleCoordsEvent"
    @pointermove.once="handleHoverEvent(true, $event)"
    @pointerover="onPointerover"
    @pointerout="handleHoverEvent(false, $event)"
    @pointerdown="handleCoordsEvent"
    @static-click="setPixel"
  />
</template>

<i18n lang="yaml">
ru:
  Game Not Found: Игра не найдена
  Game Not Active: Игра не доступна
  Same Color: Одинаковый цвет
  Color unavailable: Цвет недоступен
  Click unavailable: Клик недоступен
  Point unavailable: Координаты недоступны
en:
  Game Not Found: Game Not Found
  Game Not Active: Game Not Active
  Same Color: Same Color
  Color unavailable: Color unavailable
  Click unavailable: Click unavailable
  Point unavailable: Point unavailable
</i18n>

<script lang="ts" setup>
import {
  useImage,
  whenever,
  useEventListener,
  useWakeLock as vueUseWakeLock
} from '@vueuse/core'
import { vStaticClick } from '~/directives/static-click'
import { useGameStore } from '../../stores/game'
import { useApiClient } from '~/composables/api-client'
import { useScale } from '../../composables/use-scale'
import { usePointerCoords } from '../../composables/use-pointer-coords'
import { isMouseDown, isTouchPenMove } from '~/utils/pointer'
import { readImageArray } from '../../utils/image'
import type { SetPixelResponse, UpdatePixelActionPayload } from '../../types'

const { $snacks, $sdk } = useNuxtApp()

const { t } = useI18n()
const route = useRoute()

const gameStore = useGameStore()
const game = computed(() => gameStore.data!)
const { isWaiting, activeColor, mappedColors, availableClickCount } =
  storeToRefs(gameStore)
const { updateClickCountBySession } = gameStore

const { scale } = useScale()
const isPixelated = computed(() => scale.value > 1)

const { canvas, ctx, drawImage, fillRect } = useCanvas()

const { isGameBackgroundLoading, drawGameBackground } = useGameBackground()

const {
  gamePictureTypedArray,
  isGamePicturePending,
  fetchGamePicture,
  drawGamePicture
} = useGamePicture()

useDraw()

const {
  x,
  y,
  isInLockZone,
  handleCoordsEvent,
  handleHoverEvent,
  onPointerover
} = useCoords()

useWakeLock()

watch(
  () => isGameBackgroundLoading.value || isGamePicturePending.value,
  async loading => {
    await $sdk.module('auth').then(({ getAccount }) => getAccount())

    isWaiting.value = loading
  }
)

const setPixel = async () => {
  if (!activeColor.value || !availableClickCount.value || isInLockZone.value) {
    return
  }

  const body = {
    x: x.value,
    y: y.value,
    colorId: activeColor.value.id
  }

  try {
    const { session } = await $sdk
      .module('pixelbattle')
      .then(({ getWSInstance }) =>
        getWSInstance().emit<SetPixelResponse>('set-pixel', body)
      )

    updateClickCountBySession(session)
    const color = mappedColors.value.get(body.colorId)
    if (color) {
      fillRect(body.x, body.y, color.value)
    }
  } catch (error: any) {
    if (error.message !== 'Same Color') {
      $snacks.error(t(error.message))
    }
    throw error
  }
}

const onConnectionAction = async (isConnected: boolean) => {
  const { getWSInstance } = await $sdk.module('pixelbattle')
  const socket = getWSInstance()

  if (isConnected && socket.onceLostConnection) {
    await fetchGamePicture()
  }
}

const onUpdatePixelAction = ({ x, y, colorId }: UpdatePixelActionPayload) => {
  if (!gamePictureTypedArray.value) {
    return
  }

  const color = mappedColors.value.get(colorId)

  if (color) {
    fillRect(x, y, color.value)
    gamePictureTypedArray.value[x + y * game.value.width] = colorId
  }
}

function useCanvas() {
  const canvas = ref<HTMLCanvasElement | null>(null)
  const ctx = ref<CanvasRenderingContext2D | null>(null)

  const setCtx = () => {
    if (!canvas.value) {
      throw new Error('Canvas element does not exist')
    }

    ctx.value = canvas.value.getContext('2d')
  }

  const drawImage = (image: CanvasImageSource) => {
    if (!ctx.value) {
      throw new Error('Rendering context does not exist')
    }

    ctx.value.drawImage(image, 0, 0)
  }

  const fillRect = (fx: number, fy: number, fcolor: string) => {
    if (!ctx.value) {
      throw new Error('Rendering context does not exist')
    }

    ctx.value.fillStyle = fcolor
    ctx.value.fillRect(fx, fy, 1, 1)
  }

  onMounted(() => {
    setCtx()
  })

  return { canvas, ctx, drawImage, fillRect }
}

function useGameBackground() {
  const { state: gameBackground, isLoading: isGameBackgroundLoading } =
    useImage(() => ({ src: game.value.background_url ?? '' }))

  const drawGameBackground = () => {
    if (gameBackground.value) {
      drawImage(gameBackground.value)
    }
  }

  return { gameBackground, isGameBackgroundLoading, drawGameBackground }
}

function useGamePicture() {
  const ASYNC_DATA_KEY = 'pxl-game-picture'

  const apiClient = useApiClient()

  const {
    data: gamePictureTypedArray,
    pending: isGamePicturePending,
    execute: fetchGamePicture
  } = useAsyncData(
    ASYNC_DATA_KEY,
    async () => {
      const buffer = await apiClient.get<ArrayBuffer>(
        `/pxart/game/${game.value.id}/image`,
        { responseType: 'arrayBuffer' }
      )

      return new Uint8Array(buffer)
    },
    { server: false, lazy: true }
  )

  const gamePictureImageData = computed(() => {
    if (!gamePictureTypedArray.value) {
      return
    }

    if (import.meta.dev) {
      /* eslint-disable no-console */
      console.time('read')
    }

    const imageData = readImageArray(
      gamePictureTypedArray.value,
      game.value.width,
      game.value.height,
      mappedColors.value
    )

    if (import.meta.dev) {
      console.timeEnd('read')
      /* eslint-enable no-console */
    }

    return imageData
  })

  const drawGamePicture = () => {
    if (!gamePictureImageData.value) {
      return
    }

    if (!canvas.value) {
      throw new Error('Canvas element does not exist')
    }

    if (import.meta.dev) {
      /* eslint-disable no-console */
      console.time('draw')
    }

    const utilityCanvas = canvas.value.cloneNode() as HTMLCanvasElement
    const utilityCtx = utilityCanvas.getContext('2d')

    if (!utilityCtx) {
      throw new Error('Rendering context does not exist')
    }

    utilityCtx.putImageData(gamePictureImageData.value, 0, 0)
    drawImage(utilityCanvas)

    if (import.meta.dev) {
      console.timeEnd('draw')
      /* eslint-enable no-console */
    }
  }

  onUnmounted(() => {
    clearNuxtData(ASYNC_DATA_KEY)
  })

  return {
    gamePictureTypedArray,
    isGamePicturePending,
    gamePictureImageData,
    fetchGamePicture,
    drawGamePicture
  }
}

function useDraw() {
  const draw = () => {
    if (!ctx.value) {
      throw new Error('Rendering context does not exist')
    }

    ctx.value.clearRect(0, 0, game.value.width, game.value.height)
    drawGameBackground()
    drawGamePicture()
  }

  whenever(
    () =>
      ctx.value &&
      !(isGameBackgroundLoading.value || isGamePicturePending.value),
    draw
  )

  return { draw }
}

function useCoords() {
  const { x, y, isCanvasHovered, isInLockZone } = usePointerCoords(
    () => game.value.lockZones
  )

  const handleCoordsEvent = (evt: PointerEvent) => {
    if (isMouseDown(evt) || isTouchPenMove(evt) || !canvas.value) {
      return
    }

    /* offsetX, offsetY are calculated incorrectly in FF */
    const { left, top } = canvas.value.getBoundingClientRect()

    let newX = (evt.clientX - left) / scale.value
    let newY = (evt.clientY - top) / scale.value

    newX = Math.floor(newX)
    newY = Math.floor(newY)

    newX = Math.max(newX, 0)
    newY = Math.max(newY, 0)

    newX = Math.min(newX, game.value.width - 1)
    newY = Math.min(newY, game.value.height - 1)

    x.value = newX
    y.value = newY
  }

  // "mouseover", "mouseout", etc. can still be fired on touch devices (tested)
  const handleHoverEvent = (bool: boolean, evt: PointerEvent) => {
    if (evt.pointerType === 'mouse') {
      isCanvasHovered.value = bool
    }
  }

  const onPointerover = (evt: PointerEvent) => {
    handleCoordsEvent(evt)
    handleHoverEvent(true, evt)
  }

  return {
    x,
    y,
    isCanvasHovered,
    isInLockZone,
    handleCoordsEvent,
    handleHoverEvent,
    onPointerover
  }
}

function useWakeLock() {
  const { isSupported, isActive, release, request } = vueUseWakeLock()

  onMounted(async () => {
    // make it works on Apple devices
    useEventListener(
      window,
      'click',
      async () => {
        if (isActive.value) {
          return
        }

        await request('screen')

        if (route.path !== useRoute().path) {
          await release()
        }
      },
      { once: true }
    )

    await request('screen')
  })

  onUnmounted(async () => {
    await release()
  })

  return { isSupported, isActive, release, request }
}
</script>

<style lang="scss" module>
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% * var(--scale-correction-factor));
  height: calc(100% * var(--scale-correction-factor));
  transform: scale(calc(1 / var(--scale-correction-factor)));
  transform-origin: left top;
  background-color: #fff;
}

.pixelated {
  image-rendering: optimizeSpeed;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.notAllowed {
  cursor: not-allowed;
}
</style>
