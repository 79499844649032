import { useRouteQuery } from '@vueuse/router'
import {
  MIN_SCALE,
  MAX_SCALE,
  SCALE_FRACTIONAL_DIGITS,
  MIN_SCALE_NUDGE_DIFF,
  SCALE_NUDGE_SENSITIVITY
} from '../configs'

export const useScale = () => {
  const query = useRouteQuery('scale', 1, { transform: Number })
  const scale = useState(() => query.value)

  const setQuery = (value: number) => {
    query.value = value
  }

  const setScale = (givenValue: number) => {
    let newValue = givenValue

    newValue = Math.max(newValue, MIN_SCALE)
    newValue = Math.min(newValue, MAX_SCALE)

    newValue = Number(newValue.toFixed(SCALE_FRACTIONAL_DIGITS))

    scale.value = newValue
  }

  const nudgeScale = (degree: number) => {
    const bound = degree < 0 ? Math.min : Math.max
    const newScale = bound(
      scale.value * SCALE_NUDGE_SENSITIVITY ** degree,
      scale.value + Math.sign(degree) * MIN_SCALE_NUDGE_DIFF
    )

    setScale(newScale)
  }

  return { scale: readonly(scale), setQuery, setScale, nudgeScale }
}
