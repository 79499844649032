import { MSECONDS_IN_ONE_SECOND } from '~/consts'

const TIME_MARGIN_IN_SECONDS = 1

export const getClickCount = ({
  preAvailableCount,
  maxCount,
  firstTimestamp,
  restoreTimeInSeconds,
  timeDiff
}: {
  preAvailableCount: number
  maxCount: number
  firstTimestamp: number
  restoreTimeInSeconds: number
  timeDiff: number
}) => {
  const elapsedSeconds = Math.max(
    (Date.now() - firstTimestamp - timeDiff) / MSECONDS_IN_ONE_SECOND,
    0
  )
  const restoredCount = Math.floor(elapsedSeconds / restoreTimeInSeconds)

  return {
    availableCount: Math.min(preAvailableCount + restoredCount, maxCount),
    remainingSeconds: Math.min(
      restoreTimeInSeconds -
        Math.floor(elapsedSeconds % restoreTimeInSeconds) +
        TIME_MARGIN_IN_SECONDS,
      restoreTimeInSeconds
    )
  }
}
