export const VK_BEST_ART_TOPIC_URL = 'https://vk.com/topic-218786856_49546482'

/** Минимальный масштаб */
export const MIN_SCALE = 0.2
/** Максимальный масштаб */
export const MAX_SCALE = 50
/** Число знаков после запятой для масштаба */
export const SCALE_FRACTIONAL_DIGITS = 2
/** Минимальная разница в масштабе при относительном регулировании */
export const MIN_SCALE_NUDGE_DIFF = 0.01
/** Чувствительность регулирования масштаба */
export const SCALE_NUDGE_SENSITIVITY = 1.5
/** Минимальная чувствительность при установке масштаба щипковыми движениями */
export const MIN_SCALE_PINCH_SENSITIVITY = 1.5

export const SYNC_QUERY_DEBOUNCE = 200
export const IS_GAME_OVER = true
