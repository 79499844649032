import { useRouteQuery } from '@vueuse/router'
import { useScale } from './use-scale'
import type { MaybeRefOrGetter } from 'vue'

const QUERY_FRACTIONAL_DIGITS = 1

const useTranslateUnit = (
  name: 'x' | 'y',
  gameSizeAny: MaybeRefOrGetter<number>
) => {
  const gameSize = toRef(gameSizeAny)

  const query = useRouteQuery(name, gameSize.value / 2, { transform: Number })
  const unit = useState('use-translate-unit-' + name, () => query.value)

  const { scale } = useScale()

  const setQuery = (value: number) => {
    query.value = Number(value.toFixed(QUERY_FRACTIONAL_DIGITS))
  }

  const setUnit = (givenValue: number) => {
    let newValue = givenValue

    newValue = Math.max(newValue, 0)
    newValue = Math.min(newValue, gameSize.value)

    unit.value = newValue
  }

  const nudgeUnit = (du: number, givenScale = scale.value) => {
    setUnit(unit.value + du / givenScale)
  }

  return { setQuery, unit: readonly(unit), setUnit, nudgeUnit }
}

export const useTranslate = (
  gameWidthAny: MaybeRefOrGetter<number>,
  gameHeightAny: MaybeRefOrGetter<number>
) => {
  const {
    setQuery: setXQuery,
    unit: x,
    setUnit: setX,
    nudgeUnit: nudgeX
  } = useTranslateUnit('x', gameWidthAny)

  const {
    setQuery: setYQuery,
    unit: y,
    setUnit: setY,
    nudgeUnit: nudgeY
  } = useTranslateUnit('y', gameHeightAny)

  const translateX = computed(() => toValue(gameWidthAny) / 2 - x.value)
  const translateY = computed(() => toValue(gameHeightAny) / 2 - y.value)

  return {
    x,
    translateX,
    setXQuery,
    setX,
    nudgeX,
    y,
    translateY,
    setYQuery,
    setY,
    nudgeY
  }
}
