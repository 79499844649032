import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import {
  defaultSimpleStoreOptions,
  defaultStoreOptions
} from '@/utils/pinia/defaults'
import { useApiClient } from '@/composables/api-client'
import { createQuery, createUrl } from '@/utils/pinia/helpers'
import type { StoreQuery, SimpleStoreSetupOptions } from './types'

const storeSetup = <T>(
  defaultOptions: SimpleStoreSetupOptions,
  defaultQuery: StoreQuery
) => {
  const data = ref<T | null>(null) as Ref<T | null>

  const fetchData = async (requestQuery: StoreQuery = {}) => {
    const client = useApiClient()
    const mergedQuery = Object.assign({}, defaultQuery, requestQuery)
    const query = createQuery(defaultOptions, mergedQuery)
    const url = createUrl(defaultOptions.path)
    const requestOptions = Object.assign({}, { query })

    data.value = await client.get<T>(url, requestOptions, {
      cache: defaultOptions.cache
    })

    return data.value
  }

  const fetchUpdate = async ({
    updates = {},
    requestQuery = {}
  }: {
    updates?: Partial<T>
    requestQuery?: StoreQuery
  } = {}) => {
    const client = useApiClient()
    const mergedQuery = Object.assign({}, defaultQuery, requestQuery)
    const query = createQuery(defaultOptions, mergedQuery)
    const url = createUrl(defaultOptions.path)
    const requestOptions = Object.assign({}, { body: { ...updates }, query })

    data.value = await client.put<T>(url, requestOptions)

    return data.value
  }

  const create = async () => {
    const client = useApiClient()
    const url = createUrl(defaultOptions.path)
    const result = await client.post<T>(url)

    data.value = result
    return result
  }

  const remove = async () => {
    const client = useApiClient()
    const url = createUrl(defaultOptions.path)
    const result = await client.delete<T>(url)
    data.value = null
  }

  const updateData = (updates: Partial<T>) => {
    if (data.value) {
      data.value = Object.assign({}, data.value, updates)
    }
  }

  const flush = () => {
    data.value = null
  }

  return { data, create, remove, fetchData, fetchUpdate, updateData, flush }
}

export const createSimpleStoreWrapper = <T>() => {
  return <E extends Record<string, any> = {}>(
    key: string,
    options: SimpleStoreSetupOptions,
    setup?: (context: ReturnType<typeof storeSetup<T>>) => E
  ) => {
    const storeOptions = Object.assign(
      {},
      defaultStoreOptions,
      defaultSimpleStoreOptions,
      options
    )

    const defaultQuery = Object.assign({}, options.query)

    return defineStore(key, () => {
      const context = storeSetup<T>(storeOptions, defaultQuery)

      const extending = Object.assign({}, setup?.(context))

      return { ...context, ...extending }
    })
  }
}
