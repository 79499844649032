import { watchOnce } from '@vueuse/core'
import { createSimpleStoreWrapper } from '~/utils/pinia/simple-store-setup'
import { extendBaseColor, getMappedColors } from '../utils/color'
import { getClickCount } from '../utils/click-count'
import type { Color, Game, Session } from '../types'

const createSimpleStore = createSimpleStoreWrapper<Game>()

export const useGameStore = createSimpleStore(
  'pixelbattle-game',
  { path: '/api/v1/pixelbattle/game' },
  context => {
    const isWaiting = ref(true)

    const timeDiff = ref(0)
    const online = ref(0)

    const { activeColor, colors, mappedColors } = useColors()

    const {
      maxClickCount,
      availableClickCount,
      timerRemaining,
      updateClickCountBySession
    } = useClickCount()

    const $reset = () => {
      isWaiting.value = true
      timeDiff.value = 0
      online.value = 0
      activeColor.value = undefined
      maxClickCount.value = context.data.value?.default_click_limit ?? 0
      availableClickCount.value = 0
      timerRemaining.value = 0

      context.flush()
    }

    function useColors() {
      const activeColor = ref<Color | null>()

      const colors = computed(
        () => context.data.value?.colors.map(extendBaseColor) ?? []
      )

      const mappedColors = computed(() => getMappedColors(colors.value))

      return { activeColor, colors, mappedColors }
    }

    function useClickCount() {
      const maxClickCount = ref(0)
      const availableClickCount = ref(0)
      const timerRemaining = ref(0)

      const updateClickCountBySession = ({ clicks, playerData }: Session) => {
        if (!context.data.value) {
          return
        }

        maxClickCount.value =
          context.data.value.default_click_limit + playerData.click_limit

        const { availableCount, remainingSeconds } = getClickCount({
          preAvailableCount: clicks.available,
          maxCount: maxClickCount.value,
          firstTimestamp: clicks.first_click_ts,
          restoreTimeInSeconds: context.data.value.default_restore_time,
          timeDiff: timeDiff.value
        })

        availableClickCount.value = availableCount

        if (availableClickCount.value < maxClickCount.value) {
          timerRemaining.value = remainingSeconds
        }
      }

      watchOnce(context.data, () => {
        if (!maxClickCount.value && context.data.value?.default_click_limit) {
          maxClickCount.value = context.data.value.default_click_limit
        }
      })

      return {
        maxClickCount,
        availableClickCount,
        timerRemaining,
        updateClickCountBySession
      }
    }

    return {
      isWaiting,
      timeDiff,
      online,
      activeColor,
      colors,
      mappedColors,
      maxClickCount,
      availableClickCount,
      timerRemaining,
      updateClickCountBySession,
      $reset
    }
  }
)
