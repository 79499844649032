import type { Color } from '../types'

export const readImageArray = (
  typedArray: Uint8Array,
  width: number,
  height: number,
  mappedColors: Map<Color['id'], Color>
) =>
  typedArray.reduce(
    (acc, value, i) => {
      const rgba = mappedColors.get(value)?.rgba8 ?? [0, 0, 0, 0]
      const offset = i * rgba.length

      for (let j = 0; j < rgba.length; j++) {
        acc.data[offset + j] = rgba[j]
      }

      return acc
    },
    new ImageData(width, height)
  )
