<template>
  <div :class="$style.gameSummary">
    <ui-icon :class="$style.smileIcon" name="smile" />

    <div>
      <div :class="$style.dimensions">
        [{{ x }}, {{ y }}] <span :class="$style.scale">x{{ scale }}</span>
      </div>

      <div :class="$style.online">{{ formattedOnline }} online</div>
    </div>

    <button
      :class="$style.copyButton"
      type="button"
      :aria-label="t('copyLink')"
      @click="copy"
    >
      <ui-icon name="copy" />
    </button>
  </div>
</template>

<i18n>
  {
    "ru": {
      "copyLink": "Скопировать ссылку",
      "linkCopied": "Ссылка скопирована"
    }
  }
</i18n>

<script lang="ts" setup>
import UiIcon from '~/components/ui/icon/index.vue'
import formatNumber from '~/utils/number'
import copyToBuffer from '~/utils/copy-to-buffer'

const props = defineProps<{
  x: number
  y: number
  scale: number
  online: number
}>()

const { $snacks } = useNuxtApp()
const { t } = useI18n()

const formattedOnline = computed(() => formatNumber(props.online))

const copy = () => {
  copyToBuffer(window.location.href)
  $snacks.success(t('linkCopied'))
}
</script>

<style lang="scss" module>
.gameSummary {
  border-radius: 0.6em;
  padding: 0.8em 1em 0.6em 0.8em;
  min-width: 19em;
  color: var(--app-color-black);
  background-color: rgba(white, 0.8);
  box-shadow: 0 1.5em 1.5em rgba(black, 0.1);
  display: inline-flex;
  gap: 0.4em;
  align-items: flex-start;
}

.smileIcon {
  width: 1.6em;
  height: 1.6em;
}

.dimensions {
  font-size: 1.4em;
  font-weight: 500;
  color: var(--app-color-black);
}

.scale {
  color: #7c808b;
}

.online {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.02em;
  color: #7c808b;
}

.copyButton {
  margin-left: auto;
  width: 1.4em;
  height: 1.4em;
  color: #7c808b;
  transition: color 0.2s;
  position: relative;
  top: 0.1em;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus-visible {
    color: var(--app-color-black);
  }
}
</style>
